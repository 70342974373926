<template>
  <v-row dense>
    <v-col cols="12">
      <ul>
        <li><strong>Razão social: </strong>{{ client.company_name }}</li>
        <li><strong>Nome fantasia: </strong>{{ client.trading_name }}</li>
        <li><strong>Contato: </strong>{{ client.client_contact[0].name }}</li>
        <li><strong>Telefone: </strong>{{ client.client_contact[0].phone_number }} | {{ client.client_contact[0].cel_number }}</li>
        <li><strong>Email: </strong>{{ client.client_contact[0].email }}</li>
      </ul>
    </v-col>
    <v-col cols="12" md="12">      
      <v-simple-table class="elevation-1">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Código</th>
              <th class="text-left">Nome</th>
              <th class="text-left">Razão Social</th>
              <th class="text-left">Nome Fantasia</th>
              <th class="text-left">CPF/CNPJ</th>
            </tr>
          </thead>
          <tbody>
            <tr class="secondary--text" >
              <td>{{  '# '+crm.id }}</td>
              <td>{{ crm.client.name }}</td>
              <td>{{ crm.client.company_name  }}</td>
              <td>{{ crm.client.trading_name }}</td>
              <td>{{ crm.client.cpf_cnpj }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    crm: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    item: {
      code: 15654,
      company_name: "Nome do cliente",
      trading_name: "Nome fantasia",
      cpf_cnpj: "465.854.312-80",
      contacts: [
        {
          name: 'Nome do contato',
          department: 'Departamento',
          
        }
      ]
    },
    sellers:null
  }),
  methods: {
    getSellers() {
      this.$api
        .get("people/type/sellers")
        .then((res) => {
          this.sellers = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.getSellers();
  },
};
</script>

<style>
</style>